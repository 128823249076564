<template>
    <b-card v-if="data" no-body class="card-revenue-budget">
        <b-row class="mx-0">
            <b-col md="8" class="revenue-report-wrapper p-0 scale-8 mb-5">
                <div
                    class="d-sm-flex justify-content-between align-items-center p-1 mb-3"
                >
                    <h4 class="card-title mb-50 mb-sm-0">
                        {{ title }}
                    </h4>
                    <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center ml-75">
                            <span
                                class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer"
                            />
                            <span>الواردات {{ importTotal }}</span>
                        </div>
                        <div class="d-flex align-items-center ml-75">
                            <span
                                class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer"
                            />
                            <span>صادرات {{ exportTotal }}</span>
                        </div>
                    </div>
                </div>

                <!-- chart -->
                <vue-apex-charts
                    id="revenue-report-chart"
                    type="bar"
                    height="230"
                    :options="revenueReport.chartOptions"
                    :series="data.revenueReport.series"
                />
            </b-col>

            <b-col md="4" class="budget-wrapper">
                <!-- {{ yearReport }} -->
                <b-form-select
                    v-model="yearReport"
                    @change="getYear"
                    :options="data.years"
                    size="sm"
                    class="mt-3"
                ></b-form-select>

                <h2 class="mb-25 mt-5">ل.س{{ totalEarning }}</h2>
                <h2 class="mb-25 mt-5">صافي الربح السنوي</h2>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";
import { mapActions } from "vuex";

export default {
    components: {
        VueApexCharts,
        // BDropdown,
        // BDropdownItem,
        BCard,
        BRow,
        BCol,
    },
    directives: {
        Ripple,
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        totalEarning: {
            type: Number,
            default: () => {},
        },
        exportTotal: {
            type: Number,
            default: () => {},
        },
        importTotal: {
            type: Number,
            default: () => {},
        },
        title: {
            type: String,
            default: () => {},
        },
    },
    data() {
        return {
            yearReport: new Date().getFullYear(),
            revenue_report: {},
            revenueReport: {
                chartOptions: {
                    chart: {
                        stacked: true,
                        type: "bar",
                        toolbar: { show: false },
                    },
                    grid: {
                        padding: {
                            top: -20,
                            bottom: -10,
                        },
                        yaxis: {
                            lines: { show: false },
                        },
                    },
                    xaxis: {
                        categories: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "nov",
                            "dec",
                        ],
                        labels: {
                            style: {
                                colors: "#6E6B7B",
                                fontSize: "0.86rem",
                                fontFamily: "Montserrat",
                            },
                        },
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                    legend: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    colors: [$themeColors.primary, $themeColors.warning],
                    plotOptions: {
                        bar: {
                            columnWidth: "17%",
                            endingShape: "rounded",
                        },
                        distributed: true,
                    },
                    yaxis: {
                        labels: {
                            style: {
                                colors: "#6E6B7B",
                                fontSize: "0.86rem",
                                fontFamily: "Montserrat",
                            },
                        },
                    },
                },
            },
            // budget chart
            budgetChart: {
                options: {
                    chart: {
                        height: 80,
                        toolbar: { show: false },
                        zoom: { enabled: false },
                        type: "line",
                        sparkline: { enabled: true },
                    },
                    stroke: {
                        curve: "smooth",
                        dashArray: [0, 5],
                        width: [2],
                    },
                    colors: [$themeColors.primary, "#dcdae3"],
                    tooltip: {
                        enabled: false,
                    },
                },
            },
        };
    },
    methods: {
        ...mapActions(["getHomeDash"]),
        getYear() {
            this.getHomeDash(this.yearReport);
        },
    },
};
</script>

<style scoped>
.test {
    background-color: red;
}
</style>
