<template>
    <div class="home">
        <b-row>
            <b-col xl="6" lg="12" md="12" sm="12" class="h-100">
                <b-card class="py-2 h-100">
                    <h5>الاحصائيات</h5>
                    <b-row class="m-2">
                        <b-col
                            lg="3"
                            md="6"
                            class="d-flex justify-content-start p-1 align-items-center"
                        >
                            <unicon
                                class="icons1 flex-center"
                                name="books"
                                fill="#00CFE8"
                            ></unicon>
                            <div
                                class="mx-2 d-flex justify-content-start align-items-start flex-column"
                            >
                                <h4>{{ homeDto.subjectsCount }}</h4>
                                <span>عدد المواد</span>
                            </div>
                        </b-col>
                        <b-col
                            lg="3"
                            md="6"
                            class="mt-2 mt-md-0 d-flex justify-content-start align-items-center"
                        >
                            <unicon
                                class="icons2 flex-center"
                                name="books"
                                fill="#ff9f43"
                            ></unicon>
                            <div
                                class="mx-2 d-flex justify-content-start align-items-start flex-column"
                            >
                                <h4>{{ homeDto.unitsCount }}</h4>
                                <span>عدد الوحدات</span>
                            </div>
                        </b-col>
                        <b-col
                            lg="3"
                            md="6"
                            class="mt-2 mt-md-0 d-flex justify-content-start align-items-center"
                        >
                            <unicon
                                class="icons3 flex-center"
                                name="bookmark"
                                fill="#FA5A63"
                            ></unicon>
                            <div
                                class="mx-2 d-flex justify-content-start align-items-start flex-column"
                            >
                                <h4>{{ homeDto.lessonsCount }}</h4>
                                <span>عدد الدروس</span>
                            </div>
                        </b-col>
                        <b-col
                            lg="3"
                            md="6"
                            class="mt-2 mt-md-0 d-flex justify-content-start align-items-center"
                        >
                            <unicon
                                class="icons4 flex-center"
                                name="video"
                                fill="#4CB87F"
                            ></unicon>
                            <div
                                class="mx-2 d-flex justify-content-start align-items-start flex-column"
                            >
                                <h4>{{ homeDto.videosCount }}</h4>
                                <span>عدد الفيديوهات</span>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col xl="2" lg="4" md="4">
                <b-card class="card">
                    <div class="my-2">
                        <ecommerce-profit-chart
                            :adverts="homeDto.adsCount"
                            :title="'الاعلانات'"
                            :data="{
                                series: [
                                    {
                                        data: chartDto.adsMonthly,
                                    },
                                ],
                            }"
                        />
                    </div>
                </b-card>
            </b-col>
            <b-col xl="2" lg="4" md="4">
                <b-card class="card">
                    <div class="my-2">
                        <ecommerce-order-chart
                            :title="'الاسئلة'"
                            :question="homeDto.questionsCount"
                            :data="{
                                series: [
                                    {
                                        data: chartDto.questionsMonthly,
                                    },
                                ],
                            }"
                        />
                    </div>
                </b-card>
            </b-col>
            <b-col xl="2" lg="4" md="4">
                <b-card class="card">
                    <div class="my-2">
                        <ecommerce-order-chart
                            :title="'الاجابات'"
                            :answer="homeDto.answersCount"
                            :data="{
                                series: [
                                    {
                                        data: chartDto.questionsMonthly,
                                    },
                                ],
                            }"
                        />
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="8" lg="12" md="12">
                <b-card class="card">
                    <ecommerce-revenue-report
                        :title="'تقرير الربح'"
                        :totalEarning="homeDto.totalEarning"
                        :importTotal="homeDto.imports"
                        :exportTotal="homeDto.exports"
                        :data="{
                            years: [
                                new Date().getFullYear(),
                                ...Array.from({ length: 2 }).map(
                                    (item, index) =>
                                        new Date().getFullYear() - index - 1
                                ),
                            ],

                            revenueReport: {
                                series: [
                                    {
                                        name: 'Import',
                                        data: homeDto.importsMonthly,
                                    },
                                    {
                                        name: 'Export',
                                        data: homeDto.exportsMonthly,
                                    },
                                ],
                            },
                        }"
                        class="py-5"
                    />
                </b-card>
            </b-col>

            <b-col
                xl="4"
                lg="12"
                md="12"
                class="h-full d-flex flex-column justify-content-center"
            >
                <b-card class="card">
                    <ecommerce-earnings-chart
                        :title="'الرموز المباعة'"
                        :lastMonthCode="homeDto.thisMonthSoldCode"
                        :thisMonthCode="homeDto.soldCodesCount"
                        :compareRateCode="homeDto.compareRateSoldCode"
                        :data="{
                            series: [homeDto.compareRateSoldCode],
                        }"
                    ></ecommerce-earnings-chart>
                </b-card>

                <b-card class="card">
                    <ecommerce-earnings-chart
                        :title="'الرموز الواجب دفعها'"
                        :lastMonthCode="homeDto.lastMonthCodeDue"
                        :thisMonthCode="homeDto.thisMonthCodeDue"
                        :compareRateCode="homeDto.compareRateCodeDue"
                        :data="{ series: [homeDto.compareRateCodeDue] }"
                    ></ecommerce-earnings-chart>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="3" md="6">
                <b-card class="card">
                    <div class="my-2">
                        <div
                            class="d-flex justify-content-start align-items-start flex-column"
                        >
                            <unicon
                                class="icons7 flex-center mb-2"
                                name="user"
                                fill="#4CB87F"
                            ></unicon>
                            <div
                                class="w-100 mx-1 d-flex justify-content-start align-items-start flex-column"
                            >
                                <statistic-card-with-area-chart
                                    icon="PackageIcon"
                                    color="warning"
                                    :statistic="
                                        calcElements(chartDto.studentsMonthly)
                                    "
                                    :chart-data="[
                                        {
                                            name: 'students',
                                            data: chartDto.studentsMonthly,
                                        },
                                    ]"
                                    :statistic-title="'عدد الطلاب'"
                                />
                            </div>
                        </div>
                    </div>
                </b-card>
            </b-col>
            <b-col lg="3" md="6">
                <b-card class="card">
                    <div class="my-2">
                        <div
                            class="d-flex justify-content-start align-items-start flex-column"
                        >
                            <unicon
                                class="icons8 flex-center mb-2"
                                name="money-withdraw"
                                fill="#ff9f43"
                            ></unicon>
                            <div
                                class="w-100 mx-1 d-flex justify-content-start align-items-start flex-column"
                            >
                                <statistic-card-with-area-chart
                                    icon="PackageIcon"
                                    color="warning"
                                    :statistic="
                                        calcElements(chartDto.sellPointsMonthly)
                                    "
                                    :chart-data="[
                                        {
                                            name: 'sellpoints',
                                            data: chartDto.sellPointsMonthly,
                                        },
                                    ]"
                                    :statistic-title="'عدد النقاط البيع'"
                                />
                            </div>
                        </div>
                    </div>
                </b-card>
            </b-col>
            <b-col lg="3" md="6">
                <b-card class="card">
                    <div class="my-2">
                        <div
                            class="d-flex justify-content-start align-items-start flex-column"
                        >
                            <unicon
                                class="icons8 flex-center mb-2"
                                name="money-withdraw"
                                fill="#ff9f43"
                            ></unicon>
                            <div
                                class="w-100 mx-1 d-flex justify-content-start align-items-start flex-column"
                            >
                                <statistic-card-with-area-chart
                                    icon="PackageIcon"
                                    color="warning"
                                    :statistic="
                                        calcElements(chartDto.teachersMonthly)
                                    "
                                    :chart-data="[
                                        {
                                            name: 'teachers',
                                            data: chartDto.teachersMonthly,
                                        },
                                    ]"
                                    :statistic-title="' الاساتذة'"
                                />
                            </div>
                        </div>
                    </div>
                </b-card>
            </b-col>
            <b-col lg="3" md="6">
                <b-card class="card">
                    <div class="my-2">
                        <div
                            class="d-flex justify-content-start align-items-start flex-column"
                        >
                            <unicon
                                class="icons8 flex-center mb-2"
                                name="money-withdraw"
                                fill="#ff9f43"
                            ></unicon>
                            <div
                                class="w-100 mx-1 d-flex justify-content-start align-items-start flex-column"
                            >
                                <statistic-card-with-area-chart
                                    icon="PackageIcon"
                                    color="warning"
                                    :statistic="
                                        calcElements(chartDto.guidesMonthly)
                                    "
                                    :chart-data="[
                                        {
                                            name: 'guides',
                                            data: chartDto.guidesMonthly,
                                        },
                                    ]"
                                    :statistic-title="'مجيبو الاسئلة'"
                                />
                            </div>
                        </div>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

import EcommerceOrderChart from "./components/EcommerceOrderChart.vue";
import EcommerceProfitChart from "./components/EcommerceProfitChart.vue";
import EcommerceRevenueReport from "./components/EcommerceRevenueReport.vue";
import EcommerceEarningsChart from "./components/EcommerceEarningsChart.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { kFormatter } from "@core/utils/filter";

import chartjsData from "./chartjsData";

export default {
    components: {
        EcommerceOrderChart,
        EcommerceProfitChart,
        EcommerceRevenueReport,
        EcommerceEarningsChart,
        StatisticCardWithAreaChart,
    },

    data() {
        return {
            sum: 0,
            sumWithInitial: 0,
            chartjsData,
            data: {
                congratulations: {
                    name: "John",
                    saleToday: "48900",
                },
                statisticsItems: [
                    {
                        icon: "TrendingUpIcon",
                        color: "light-primary",
                        title: "230k",
                        subtitle: "Sales",
                        customClass: "mb-2 mb-xl-0",
                    },
                    {
                        icon: "UserIcon",
                        color: "light-info",
                        title: "8.549k",
                        subtitle: "Customers",
                        customClass: "mb-2 mb-xl-0",
                    },
                    {
                        icon: "BoxIcon",
                        color: "light-danger",
                        title: "1.423k",
                        subtitle: "Products",
                        customClass: "mb-2 mb-sm-0",
                    },
                    {
                        icon: "DollarSignIcon",
                        color: "light-success",
                        title: "$9745",
                        subtitle: "Revenue",
                        customClass: "",
                    },
                ],
                statisticsOrder: {
                    series: [
                        {
                            name: "2020",
                            data: [45, 85, 65, 45, 65],
                        },
                    ],
                },
                statisticsProfit: {
                    series: [
                        {
                            data: [0, 20, 5, 30, 15, 45],
                        },
                    ],
                },
                earningsChart: {
                    series: [53, 16, 31],
                },
                revenue: {
                    years: ["2020", "2019", "2018"],
                    price: "25,852",
                    budget: "56,800",
                    revenueReport: {
                        series: [
                            {
                                name: "Earning",
                                data: [
                                    95, 177, 284, 256, 105, 63, 168, 218, 72,
                                ],
                            },
                            {
                                name: "Expense",
                                data: [
                                    -145, -80, -60, -180, -100, -60, -85, -75,
                                    -100,
                                ],
                            },
                        ],
                    },
                    budgetChart: {
                        series: [
                            {
                                data: [
                                    61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62,
                                ],
                            },
                            {
                                data: [
                                    20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27,
                                ],
                            },
                        ],
                    },
                },
                companyTable: [
                    {
                        avatarImg: require("@/assets/images/icons/toolbox.svg"),
                        title: "Dixons",
                        subtitle: "meguc@ruj.io",
                        avatarIcon: "MonitorIcon",
                        avatarColor: "light-primary",
                        avatarTitle: "Technology",
                        viewTitle: "23.4k",
                        viewsub: "in 24 hours",
                        revenue: "891.2",
                        sales: "68",
                        loss: true,
                    },
                    {
                        avatarImg: require("@/assets/images/icons/parachute.svg"),
                        title: "Motels",
                        subtitle: "vecav@hodzi.co.uk",
                        avatarIcon: "CoffeeIcon",
                        avatarColor: "light-success",
                        avatarTitle: "Grocery",
                        viewTitle: "78k",
                        viewsub: "in 2 days",
                        revenue: "668.51",
                        sales: "97",
                        loss: false,
                    },
                    {
                        avatarImg: require("@/assets/images/icons/brush.svg"),
                        title: "Zipcar",
                        subtitle: "davcilse@is.gov",
                        avatarIcon: "WatchIcon",
                        avatarColor: "light-warning",
                        avatarTitle: "Fashion",
                        viewTitle: "162",
                        viewsub: "in 5 days",
                        revenue: "522.29",
                        sales: "62",
                        loss: false,
                    },
                    {
                        avatarImg: require("@/assets/images/icons/star.svg"),
                        title: "Owning",
                        subtitle: "us@cuhil.gov",
                        avatarIcon: "MonitorIcon",
                        avatarColor: "light-primary",
                        avatarTitle: "Technology",
                        viewTitle: "214",
                        viewsub: "in 24 hours",
                        revenue: "291.01",
                        sales: "88",
                        loss: false,
                    },
                    {
                        avatarImg: require("@/assets/images/icons/book.svg"),
                        title: "Cafés",
                        subtitle: "pudais@jife.com",
                        avatarIcon: "CoffeeIcon",
                        avatarColor: "light-success",
                        avatarTitle: "Grocery",
                        viewTitle: "208",
                        viewsub: "in 1 week",
                        revenue: "783.93",
                        sales: "16",
                        loss: true,
                    },
                    {
                        avatarImg: require("@/assets/images/icons/rocket.svg"),
                        title: "Kmart",
                        subtitle: "bipri@cawiw.com",
                        avatarIcon: "WatchIcon",
                        avatarColor: "light-warning",
                        avatarTitle: "Fashion",
                        viewTitle: "990",
                        viewsub: "in 1 month",
                        revenue: "780.05",
                        sales: "78",
                        loss: false,
                    },
                    {
                        avatarImg: require("@/assets/images/icons/speaker.svg"),
                        title: "Payers",
                        subtitle: "luk@izug.io",
                        avatarIcon: "WatchIcon",
                        avatarColor: "light-warning",
                        avatarTitle: "Fashion",
                        viewTitle: "12.9k",
                        viewsub: "in 12 hours",
                        revenue: "531.49",
                        sales: "42",
                        loss: false,
                    },
                ],
                meetup: {
                    mediaData: [
                        {
                            avatar: "CalendarIcon",
                            title: "Sat, May 25, 2020",
                            subtitle: "10:AM to 6:PM",
                        },
                        {
                            avatar: "MapPinIcon",
                            title: "Central Park",
                            subtitle: "Manhattan, New york City",
                        },
                    ],
                    avatars: [
                        {
                            avatar: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
                            fullName: "Billy Hopkins",
                        },
                        {
                            avatar: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
                            fullName: "Amy Carson",
                        },
                        {
                            avatar: require("@/assets/images/portrait/small/avatar-s-8.jpg"),
                            fullName: "Brandon Miles",
                        },
                        {
                            avatar: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
                            fullName: "Daisy Weber",
                        },
                        {
                            avatar: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
                            fullName: "Jenny Looper",
                        },
                    ],
                },
                goalOverview: {
                    completed: "786,617",
                    inProgress: "13,561",
                    series: [83],
                },
                transactionData: [
                    {
                        mode: "Wallet",
                        types: "Starbucks",
                        avatar: "PocketIcon",
                        avatarVariant: "light-primary",
                        payment: "-$74",
                        deduction: true,
                    },
                    {
                        mode: "Bank Transfer",
                        types: "Add Money",
                        avatar: "CheckIcon",
                        avatarVariant: "light-success",
                        payment: "+$480",
                        deduction: false,
                    },
                    {
                        mode: "Paypal",
                        types: "Add Money",
                        avatar: "DollarSignIcon",
                        avatarVariant: "light-danger",
                        payment: "+$480",
                        deduction: false,
                    },
                    {
                        mode: "Mastercard",
                        types: "Ordered Food",
                        avatar: "CreditCardIcon",
                        avatarVariant: "light-warning",
                        payment: "-$23",
                        deduction: true,
                    },
                    {
                        mode: "Transfer",
                        types: "Refund",
                        avatar: "TrendingUpIcon",
                        avatarVariant: "light-info",
                        payment: "+$98",
                        deduction: false,
                    },
                ],
            },
            analytics: {
                ordersRecevied: {
                    series: [
                        {
                            name: "Orders",
                            data: [10, 15, 8, 15, 7, 12, 8],
                        },
                    ],
                },
            },
        };
    },
    computed: {
        ...mapState({
            homeDto: (state) => state.home.homeDto,
            year: (state) => state.home.year,
            chartDto: (state) => state.home.chartDto,
        }),
    },

    created() {
        this.getHomeDash();
        this.getCharts(this.year);
    },
    methods: {
        ...mapActions(["getHomeDash", "getCharts"]),
        kFormatter,
        calcElements(items) {
            if (items)
                return (this.sumWithInitial = items.reduce(
                    (previousValue, currentValue) =>
                        previousValue + currentValue,
                    this.sum
                ));
        },
    },
};
</script>

<style lang="scss">
.home {
    .unicon {
        padding: 10px;
        border-radius: 50%;
        &.icons1 {
            background-color: rgba(0, 207, 232, 0.12);
        }
        &.icons2 {
            background-color: rgba(115, 103, 240, 0.12);
        }
        &.icons3 {
            background-color: rgba(234, 84, 85, 0.12);
        }
        &.icons4 {
            background-color: rgba(40, 199, 111, 0.12);
        }
        &.icons5 {
            background-color: rgba(255, 159, 67, 0.12);
        }
        &.icons6 {
            background-color: rgba(234, 84, 85, 0.12);
        }
        &.icons7 {
            background-color: rgba(40, 199, 111, 0.12);
        }
        &.icons8 {
            background-color: rgba(115, 103, 240, 0.12);
        }
    }
    h4 {
        font-weight: bold;
    }
}
</style>
